@import 'mixins';


::selection{
    background-color: $yellow;
    color: black;
}

::-webkit-scrollbar{
    // display: none;
}
*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

.order-button{
    cursor: pointer;
}

.form-error{
    margin-top: 20px;
    text-align: center;       
    // background-color: orange;
    width: 100%;
    word-wrap: break-word;
    min-height: 20px;
}

h3{
    // background-color: pink;
    text-align: center;
    font-size: 36px;
    font-weight: 300;
}

.site-header{
    width: 100%;
    height: 150px;
    // background-color: red;
    @include flexColumn(flex-start);
    align-items: center;

    &__top-container{
        max-width: 1200px;
        width: 100%;
        height: 100px;
        // background-color: yellow;
        @include flexRow(space-between);
    }

    &__mobile-button{
        width: 40px;
        height: 40px;
        // background-color: blue;
        @include flexRow(center);
        align-items: center;
        position: relative;
        *{
            transition: 0.3s;
        }
        path{
            stroke: $blue;
        }
        display: none
    }

    &__mobile-button_active{
        path{
            position: absolute;
            &:nth-child(1){
                transform: rotate(45deg) translate(5px, -5px);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                transform: rotate(-45deg) translate(-12px, -2px);
                // transform: translate(0px, -28px);

            }
        }
    }

    &__logo-block{
        width: 300px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(center);
    }

    &__logo{
        max-width: 100%;
        max-height: 100%;
    }

    &__work-mode-container{
        width: 200px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__work-mode-img-container{
        width: 100%;
        height: 26px;
        @include flexRow(center);
        margin-top: 10px;
        margin-bottom: 5px;
        // background-color: plum;
    }

    &__work-mode-text{
        text-align: center;
    }

    &__address-container{
        width: 200px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__address-img-container{
        width: 100%;
        height: 26px;
        @include flexRow(center);
        margin-top: 10px;
        margin-bottom: 5px;
        // background-color: plum;
    }

    &__address-text{
        text-align: center;
    }

    &__buttons-container{
        width: 300px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(center);
        align-items: center;
    }

    &__phone{
        text-decoration: none;
        color: $yellow;
        font-size: 33px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    &__button{
        width: 296px;
        height: 40px;
        // background-color: plum;
        border-radius: 5px;
        text-align: center;
        @include flexColumn(center);
        text-decoration: none;
        color: $blue;
        font-weight: 500;
        border: 2px solid $blue;
        transition: 0.3s;
        &:hover{
            background-color: $yellow;
            border-color: $yellow;
        }
    }

    &__bottom-container{
        width: 100%;
        height: 50px;
        background-color: $blue;
        @include flexRow(center);
    }

    &__nav{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexRow(center);
    }

    &__menu{
        width: 100%;
        height: 100%;
        // background-color: plum;
        @include flexRow(center);
        list-style: none;
    }

    &__menu-item{
        // background-color: orange;
        margin: 0px 35px 0px 35px;
        @include flexColumn(center);
        text-align: center;
        // padding: 0px 25px 0px 25px;
        &:first-child{
            margin-left: 0px;
        }
        &:last-child{
            margin-right: 0px;
            font-weight: 600;
        }
    }

    &__menu-link{
        font-size: 20px;
        text-decoration: none;
        color: $yellow;
        @include flexColumn(center);
        align-items: center;
        // background-color: red;
        position: relative;
        &::after{
            display: block;
            content: "";
            width: 0%;
            height: 4px;
            background-color: $yellow;
            position: absolute;
            top: 33px;
            transition: 0.3s;
        }
        &:hover{
            &::after{
                width: 100%;
            }
        }
    }
}

.main-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    background-image: url(./../src/img/main-section.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0px;
    @include flexRow(center);
    flex-wrap: wrap;
    min-height: 700px;

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__content-container{
        max-width: 100%;
        width: 100%;
        height: 100%;
        // background-color: pink;
        padding: 0px 10px;
        @include flexColumn(flex-start);
        margin: 0px 60px;
    }

    &__header{
        margin-top: 200px;
        color: white;
        text-align: center;
        font-size: 36px;
        font-weight: 400;
        display: flex;
        min-height: 301px;
        text-align: center;
        @include flexRow(center);
        // background-color: red;
    }

    &__buttons-container{
        width: 100%;
        height: 50px;
        // background-color: plum;
        // margin-top: 10px;
        @include flexRow(center);
    }

    &__button{
        text-decoration: none;
        color: $yellow;
        border: 2px solid $yellow;
        height: 46px;
        border-radius: 5px;
        text-align: center;
        padding: 0px 10px;
        @include flexColumn(center);
        transition: 0.3s;
        &:last-child{
            margin-left: 20px;
        }
        &:hover{
            background-color: $blue;
            border-color: $blue;
        }
    }

    &__form-container{
        max-width: 400px;
        width: 100%;
        height: 100%;
        // background-color: pink;
        margin: 0px 60px;
    }
    
    &__form{
        padding: 30px 10px;
        width: 100%;
        height: 100%;
        // background-color: plum;
        @include flexColumn(flex-start);
        align-items: center;
        border-radius: 5px;
        backdrop-filter: blur(15px);
        box-shadow: 0px 0px 0px 1px $yellow;
    }

    &__form-header{
        // margin-top: 30px;
        color: $yellow;
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 30px;
    }

    &__input{
        padding: 0px 0px 0px 5px;
        width: 200px;
        height: 30px;
        margin-top: 10px;
        border: none;
        background-color: transparent;
        box-shadow: 0px 2px 0px 0px $yellow;
        outline: none;
        color: white;
        font-size: 18px;
        &::placeholder{
            color: darken(white, 15%);
        }
    }

    &__form-button{
        margin-top: 20px;
        width: 200px;
        height: 50px;
        background-color: $blue;
        border: none;
        outline: none;
        color: $yellow;
        border-radius: 5px;
        font-size: 20px;
        transition: 0.3s;
        cursor: pointer;
        &:hover{
            background-color: $yellow;
            color: $blue;
        }
    }
}

.brands-section{
    margin-top: 100px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        width: 1180px;
        height: 100%;
        @include flexColumn(flex-start);
    }

    &__header{
        text-align: center;
        font-size: 34px;
        font-weight: 500;
    }

    &__brands-container{
        margin-top: 40px;
        width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__brand-block{
        width: 166px;
        min-height: 20px;
        background-color: plum;
        box-shadow: 0px 0px 3px 0px $yellow;
        padding: 10px 5px;
        margin: 10px;
        @include flexRow(center);
        align-items: center;
        background-color: white;
        text-align: center;
        word-wrap: break-word;
        text-decoration: none;
        color: $blue;
        // pointer-events: none;
        &:hover{
            // pointer-events: all;
            background-color: $yellow;
            // transform: scale(1.1);
        }
    }

    &__brand-img{
        max-width: 100%;
        max-height: 100%;
    }

}

.services-section{
    padding: 50px 0px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__table{
        width: 100%;
        margin-top: 50px;
    }

    tr{
        height: 30px;
        &:nth-child(odd){
            background-color: darken(white, 10%);
        }
    }

    th{
        // background-color: plum;
        // width: 30%;
    }

    &__name{
        background-color: $blue;
        color: $yellow;
        width: 70%;
    }

    
    &__quantity{
        background-color: $blue;
        color: $yellow;
        width: 15%;
    }

    &__price{
        background-color: $blue;
        color: $yellow;
        width: 15%;
    }
    
    td{
        text-align: center;
    }
    &__link{
        // transition: 0.3s;
        // text-decoration: none;
        color: $dark;
        &:hover{
            color: $yellow;
            transform: scale(1.1);
            // font-size: 17px;
        }
    }
}

.cats-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    background-color: darken(white, 5%);
    padding: 50px 0px;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        text-align: center;
        font-size: 34px;
        font-weight: 500;
    }

    &__cats-container{
        margin-top: 30px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__cat{
        width: 240px;
        // height: 190px;
        padding: 15px 15px;
        // background-color: pink;
        @include flexColumn(flex-start);
        text-decoration: none;
        transition: 0.3s;
        &:hover{
            box-shadow: 0px 0px 1px 0px black;
            background-color: white;
            // transform: scale(1.1);
        }
    }

    &__cat-img-container{
        width: 100%;
        height: 150px;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
    }

    &__cat-img{
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // display: none;
    }

    &__cat-name{
        width: 100%;
        text-align: center;
        // background-color: plum;
        margin-top: 5px;
        word-wrap: break-word;
        color: black;
    }
}

.steps-section{
    width: 100%;
    // height: 300px;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        text-align: center;
        font-size: 34px;
        font-weight: 500;
    }

    &__steps-container{
        width: 100%;
        // background-color: pink;
        margin-top: 30px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__step{
        width: 200px;
        // height: 300px;
        // background-color: plum;
        @include flexColumn(flex-start);
        text-decoration: none;
        margin: 20px 20px;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
        }
    }

    &__img-container{
        width: 100%;
        height: 100px;
        // background-color: orange;
        @include flexRow(center);
        align-items: center;
    }

    &__img{
        max-width: 50%;
        max-height: 100%;
    }

    &__text{
        width: 100%;
        // background-color: orange;
        margin-top: 5px;
        word-wrap: break-word;
        text-align: center;
        color: black;
    }
}

.reviews-section{
    padding: 50px 0px;
    width: 100%;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        text-align: center;
        font-size: 34px;
        font-weight: 500;
    }

    &__slider{
        margin-top: 30px;
        width: 100%;
        // height: 500px;
        // background-color: pink;
        @include flexRow(space-between);
        align-items: center;
    }

    &__slider-nav{
        width: 50px;
        height: 100%;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
    }
    
    &__nav-button{
        width: 40px;
        height: 40px;
        // background-color: orange;
        transition: 0.3s;
        cursor: pointer;

        svg{
            width: 100%;
            height: 100%;
            path{
                fill: $yellow;
            }
        }

        &:hover{
            transform: scale(1.1);
        }

        &:active{
            transition: 0s;
            transform: scale(0.9);
        }
    }

    &__slides-container{
        width: 90%;
        height: 100%;
        // background-color: plum;
        @include flexColumn(flex);
        align-items: center;
    }

    &__slide{
        // background-color: aqua;
        min-height: 350px;
        &:not(:first-child){
            display: none;
        }
    }

    &__img-block{
        margin-top: 30px;
        width: 100%;
        height: 150px;
        // background-color: orange;
        @include flexRow(center);
    }

    &__img{
        width: 150px;
        border-radius: 100px;
        height: 150px;
    }

    &__reviewer-name{
        width: 100%;
        // height: 30px;
        // background-color: orange;
        margin-top: 7px;
        text-align: center;
        color: $dark;
    }

    &__text{
        width: 100%;
        // height: 30px;
        // background-color: orange;
        margin-top: 20px;
        text-align: center;
        // color: $dark;
    }
}

.questions-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        // background-color: yellow;
        max-width: 1200px;
        width: 100%;
        height: 100%;
        @include flexColumn(flex-start);
    }

    &__header{
        text-align: center;
        font-size: 34px;
        font-weight: 500;
    }

    &__questions-container{
        width: 100%;
        // height: 400px;
        // background-color: pink;
        margin-top: 50px;
        @include flexColumn(flex-start);
    }

    &__question-block{
        width: 100%;
        min-height: 40px;
        // background-color: plum;
        box-shadow: 0px 3px 0px 0px black;
        @include flexColumn(flex-start);
        &:not(:first-child){
            margin-top: 30px;
        }
    }

    &__header-block{
        cursor: pointer;
        &:not(.questions-section__header-block_active){
            // background-color: red;
            &:hover{
                color: $yellow;
                svg{
                    path{
                        stroke: $yellow;
                    }
                }
            }
        }
    }

    &__header-block{
        width: calc(100% - 10px);
        padding: 0px 5px 0px 5px;
        min-height: 40px;
        // background-color: orange;
        @include flexRow(space-between);
        align-items: center;
        transition: 0.3s;
    }
    
    &__header-block_active{
        color: $yellow;
    }

    &__question-header{
        // background-color: aqua;
        max-width: 80%;
        font-weight: 400;
        font-size: 24px;
    }

    &__question-header_active{
        // color: orange;
    }

    &__question-button{
        transition: 0.3s;
        cursor: pointer;
        path{
            transition: 0.3s;
            stroke: black;
        }       
    }

    &__question-button_active{
        transform: rotate(45deg);
        path{
            stroke: $yellow;
        }

    }

    &__answer{
        width: calc(100% - 10px);
        padding: 5px 5px 10px 5px;
        // background-color: orange;
        margin-top: 5px;
        display: none;
    }
}

.areas-section{
    padding: 50px 0px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    background-image: url(./../src/img/areas.jpg);
    @include flexRow(center);
    background-size: cover;
    background-position: 51% 49%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
    
    
    &__cover{
        // padding: 50px 0px;
        margin-top: -50px;
        width: 100%;
        height: 100%;
        opacity: 0.81;
        background-color: $yellow;
        position: absolute;
    }

    &__container{
        max-width: 1080px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
        flex-wrap: wrap;
        // position: absolute;
        z-index: 1;
    }

    &__buttons-block{
        max-width: 400px;
        // width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__text{
        width: 100%;
        // background-color: plum;
        // letter-spacing: 1px;
        font-size: 19px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    &__toggle{
        width: 200px;
        height: 50px;
        // background-color: plum;
        margin-top: 10px;
        text-decoration: none;
        border-radius: 5px;
        background-color: $blue;
        @include flexRow(center);
        align-items: center;
        color: white;
        font-size: 18px;
        transition: 0.3s;
        cursor: pointer;
        &:hover{
            color: $yellow;
        }
    }

    &__order-button{
        width: 250px;
        height: 60px;
        background-color: $blue;
        margin-top: 20px;
        border-radius: 5px;
        @include flexRow(center);
        align-items: center;
        color: $yellow;
        text-decoration: none;
        font-size: 20px;
        transition: 0.3s;
        svg{
            transition: 0.3s;
            fill: $yellow;
            margin-left: 10px;
        }

        &:hover{
            transform: scale(1.1);
            color: white;
            svg{
                fill: white;
            }
        }
    }

    &__areas-container{
        padding: 10px 0px 10px 0px;
        max-width: 600px;
        width: 100%;
        max-height: 400px;
        height: 100%;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
        // overflow: hidden;
        overflow-y: scroll;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0px 0px 5px 0px white;
        // display: none;

        &::-webkit-scrollbar{
            width: 3px;
            // background-color: red;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $blue;
            // width: 3px;
            border-radius: 5px;
        }

        &:nth-child(3){
            display: none;
        }
    }

    &__area{
        width: 150px;
        height: 50px;
        text-align: center;
        @include flexRow(center);
        align-items: center;
        text-decoration: none;
        color: $blue;
        border-radius: 5px;
        background-color: $yellow;
        margin: 15px 15px 15px 15px;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
            color: white;
            box-shadow: 0px 0px 5px 0px $yellow;
        }
    }
}

.map-section{
    width: 100%;
    height: 550px;
    background-color: rgb(236, 236, 236);
    position: relative;
    
    &__contacts-block{
        // width: 300px;
        // height: 270px;
        padding: 20px 20px 20px 20px;
        // background-color: yellow;
        background-color: white;
        position: absolute;
        z-index: 1;
        left: 100px;
        top: 60px;
        border-radius: 15px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.315);
        @include flexColumn(flex-start);
    }
    
    &__contact-block{
        width: 100%;
        height: 40px;
        // background-color: pink;
        @include flexRow(flex-start);
        align-items: center;
        margin-bottom: 5px;
        color: $dark;
        
        svg{
            width: 30px;
            height: 30px;
        }
    }
    
    &__link{
        @include flexRow(flex-start);
        width: 100%;
        height: 100%;
        // background-color: red;
        align-items: center;
        color: $dark;
        text-decoration: none;
        transition: 0.3s;

        &:hover{
            transform: scale(1.1);
        }
    }

    &__contact-text{
        margin-left: 10px;
    }
    
    &__map-block{
        width: 100%;
        height: 100%;
        // z-index: 100;
        position: absolute;
        // box-shadow: 0px 10px 0px 0px black inset;
        // filter: blur(1px);
        // filter: saturate(250%);
        // filter: blur(1px);
    }

}

.site-footer{
    width: 100%;
    min-height: 100px;
    background-color: $blue;
    // background-color: red;
    padding: 30px 0px 0px 0px;
    @include flexRow(center);

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__menu-block{
        height: 100%;
        // background-color: pink;
        @include flexColumn(center);
        margin-top: 30px;
        margin: 30px 20px 0px 20px;
    }

    &__menu-block.logo-block{
        margin-top: 16px;
        // background-color: red;
        // height: 100%;
    }
    
    &__menu-block.nav-block{
        @include flexRow(center);
        align-items: center;
        flex-wrap: wrap;
    }

    &__menu-item{
        margin: 0px 15px;
        height: 100%;
        // background-color: plum;
        @include flexColumn(center);
        text-decoration: none;
        color: white;
        margin-bottom: 15px;
        // margin-bottom: 30px;
        p{
            transition: 0.3s;
        }

        &:first-child{
            margin-left: 0px;
        }

        &:last-child{
            margin-right: 0px;
        }

        &:hover{
            p{
                // transform: scale(1.1);
                color: $yellow;
            }
        }
    }

    &__menu-separator{
        width: 1px;
        height: 40%;
        background-color: lightgray;
    }

    &__author-link{
        color: lightgray;
        text-decoration: none;
        text-align: center;
        margin-bottom: 20px;
    }

}

.modal-window{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    position: absolute;
    z-index: 99999;
    @include flexRow(center);
    align-items: center;
    display: none;

    &__form{
        padding: 30px;
        // width: 500px;
        // height: 400px;
        background-color: white;
        border-radius: 5px;
        @include flexColumn(flex-start);
        align-items: center;
        position: relative;
    }

    &__close-button{
        position: absolute;
        // background-color: red;
        width: 20px;
        height: 20px;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 25px);
        bottom: calc(100% - 25px);
        cursor: pointer;
        svg{
            transition: 0.3s;
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__header{
        font-size: 20px;
        width: 200px;
    }

    &__user-input{
        margin-top: 20px;
        width: 200px;
        height: 30px;
        border: 1px solid $blue;
        border-radius: 5px;
        padding: 0px 0px 0px 5px;
        outline: none;
        font-size: 18px;
        font-weight: 300;
    }

    &__button{
        width: 210px;
        height: 40px;
        background-color: $dark;
        margin-top: 15px;
        color: white;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            background-color: $blue;
        }
    }

    &__policy-text{
        width: 210px;
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: lightgrey;
    }
}

.up-button{
    width: 40px;
    height: 40px;
    // border-radius: 100px 0px 0px 100px;
    background-color: $yellow;
    border-radius: 15px;
    // box-shadow: 0px 0px 0px 5px $blue;
    // background-color: red;
    @include flexColumn(center);
    align-items: center;
    // position: fixed;
    position: fixed;
    top: calc(95% - 50px);
    left: calc(100% - 50px);
    z-index: 100;
    display: none;

    .modal-button__svg-container{
        width: 30px;
        height: 30px;
        border-radius: 65px;
        // background-color: yellow;
        @include flexColumn(center);
        align-items: center;
    }
    svg{
        // background-color: white;
        // padding: 13px;
        // border-radius: 30px;
        width: 40px;
        height: 40px;
        path{
            fill: $blue;
        }
    }

    &:hover{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }

    &:active{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }
}

@keyframes modalCallAnim {
    0%{transform: rotate(0deg);}
    50%{transform: rotate(10deg);}
    100%{transform: rotate(0deg);}
}

.call-button{
    box-shadow: 0px 0px 0px 5px white;
    border-radius: 15px;
    width: 40px;
    height: 40px;
    // border-radius: 0px 100px 100px 0px;
    background-color: $yellow;
    // box-shadow: 0px 0px 0px 5px $blue;
    @include flexColumn(center);
    align-items: center;
    // position: fixed;
    position: fixed;
    top: calc(95% - 65px);
    right: calc(100% - 60px);
    z-index: 100;
    display: none;
    
    .modal-button__svg-container{
        width: 30px;
        height: 30px;
        border-radius: 65px;
        // background-color: white;
        @include flexColumn(center);
        align-items: center;
    }
    
    svg{
        // display: none;
        animation: modalCallAnim 1s infinite;
        // background-color: white;
        // padding: 13px;
        // border-radius: 30px;
        fill: $blue;
        width: 40px;
        height: 40px;
        path{
            fill: $blue;
        }
    }

    &:hover{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            animation-play-state: paused;
            transform: scale(1.1);
        }
    }

    &:active{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }
}

.error-block{
    width: 100%;
    height: 90vh;
    // background-color: red;
    @include flexRow(center);
    align-items: center;
    text-align: center;

    &__container{
        @include flexColumn(center);
        // background-color: yellow;
        height: 100%;
    }

    &__message{
        font-size: 36px;
    }

    &__link{
        color: $blue;
        font-size: 36px;
    }
}

@media (max-width: 1200px){
    .site-header{
        // background-color: red;
        // display: none;
        height: unset;
        position: relative;
        &__container{
            // background-color: yellow;
            justify-content: space-around;
            align-items: center;
            position: relative;
        }

        &__top-container{
            width: calc(100% - 20px);
            // background-color: red;
            align-items: center;
            padding: 0px 10px;
        }

        &__logo-block{
            width: 200px;
        }

        &__work-mode-container{
            display: none;
        }

        &__address-container{
            display: none;
        }

        &__buttons-container{
            display: none;
        }

        &__mobile-button{
            @include flexColumn(center);
            align-items: center;
        }

        &__bottom-container{
            position: absolute;
            height: unset;
            margin-top: 100px;
            display: none;
            z-index: 2;
        }
        
        &__menu{
            @include flexColumn(flex-start);
        }

        &__menu-item{
            margin: 10px 0px;
        }

        // &__nav{
        //     position: absolute;
        //     background-color: white;
        //     margin-top: 261px;
        //     width: 100%;
        //     @include flexRow(center);
        //     // display: none;
        // }
        
        // &__menu{
        //     padding: 0px 0px 10px 0px;
        //     @include flexColumn(flex-start);
        //     display: none;
        //     // background-color: orange;
        // }

        // &__menu-item{
        //     // background-color: pink;
        //     height: unset;
        //     margin-top: 10px;
        // }

        // &__order-button{
        //     margin-top: 10px;
        //     box-shadow: none;
        // }
    }

    .main-section{
        &__content-container{
            margin: 0px;
            text-align: center;
            margin-bottom: 40px;
        }
        &__buttons-container{
            justify-content: center;
        }
    }

    .areas-section{
        text-align: center;
        &__container{
            // background-color: red;
            @include flexRow(center);
            align-items: center;
        }
        &__text{
            width: 90%;
            // background-color: yellow;
        }
        &__areas-container{
            margin-top: 20px;
        }
    }

    .map-section{
        // display: none;
        height: unset;
        // background-color: red;
        @include flexRow(center);
        flex-wrap: wrap;
        
        &__contacts-block{
            position: relative;
            left: 0px;
            top: 0px;
            border-radius: 0px;
            box-shadow: unset;
            background-color: transparent;
            // background-color: red;
            align-items: center;
        }

        &__contact-block{
            width: unset;
            // @include flexRow(center);
            // align-items: center;
        }

        &__map-block{
            position: relative;
            width: 100%;
            height: 400px;
        }

    }
}